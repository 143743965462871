import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
// import Swiper from "react-id-swiper"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SkuTile from "../../components/SkuTile"

import styles from "./ss22.module.scss"

const Resort23 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "absolute-40-nappa-crystals-sandals",
      "solar-65-nappa-crystals-sandals",
      "rise-up-110-nappa-crystals-sandals",
      "lure-90-nappa-crystals-sandals",
      "admirer-10-velvet-sandals",
      "luster-10-nappa-laminata-sandals",
      "luster-10-nappa-sandals",
      "grass-roots-45-suede-crystals-pumps",
      "grass-roots-45-suede-pumps",
      "limelight-90-suede-pumps",
      "trinity-mid-calf-75-suede-boots",
      "trinity-mid-calf-75-nappa-boots",
      "monday-morning-suede-boots",
      "buckle-up-nappa-boots",
      "off-road-50-nappa-boots",
      "off-road-50-suede-boots",
      "off-duty-suede-boots",
      "cantor-boots",
      "rise-up-110-nappa-sandals",
      "lure-nappa-sandals",
      "solar-65-nappa-sandals",
      "absolute-40-nappa-sandals",
    ].filter(handle => nodes.find(style => style.handle === handle))
      .map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        sku.handle = style.handle
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  // const swiperParams = {
  //   containerClass: `${styles.swiperContainer} swiper-container`,
  //   WrapperEl: "ol",
  //   threshold: 10,
  //   spaceBetween: 0,
  //   slidesPerView: 1,
  //   loop: true,
  //   pagination: {
  //     el: `.swiper-pagination`,
  //     clickable: true,
  //   },
  //   // allowTouchMove: false,
  //   on: {
  //     slideChangeTransitionEnd: function() {
  //       if (this) {
  //         const i = this.activeIndex
  //         const video = this.slides[i].querySelector("video")
  //         if (video)
  //           video.play()
  //       }
  //     },
  //   },
  // }

  // const [swiper1, getSwiper1] = useState(null)
  // const swiperParams1 = {
  //   ...swiperParams,
  //   getSwiper: getSwiper1,
  // }

  return (
    <div className={styles.ss22}>
      <SEO
        title="Resort '23"
        description="New Collection"
        url={location.pathname}
      />

      <header className={styles.header}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/1129_hp_desktop_1_2000x.jpg?v=1669766598",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/1129_hp_mobile_1_850x.jpg?v=1669766598",
          }}
          altText="Resort 23 Image"
          className={styles.imgFullWidth}
        />
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          Resort '23
        </Heading>
      </header>

      <ul className={styles.grid1resort23}>
        {allSkus.filter(sku => [
          "absolute-40-nappa-crystals-sandals",
          "solar-65-nappa-crystals-sandals",
          "rise-up-110-nappa-crystals-sandals",
          "lure-90-nappa-crystals-sandals",
          "admirer-10-velvet-sandals",
          "luster-10-nappa-laminata-sandals",
          "luster-10-nappa-sandals",
          "grass-roots-45-suede-crystals-pumps",
          "grass-roots-45-suede-pumps",
          "limelight-90-suede-pumps",
          "trinity-mid-calf-75-suede-boots",
          "trinity-mid-calf-75-nappa-boots",
          "monday-morning-suede-boots",
          "buckle-up-nappa-boots",
          "off-road-50-nappa-boots",
          "off-road-50-suede-boots",
          "off-duty-suede-boots",
          "cantor-boots",
          "rise-up-110-nappa-sandals",
          "lure-nappa-sandals",
          "solar-65-nappa-sandals",
          "absolute-40-nappa-sandals",
        ].includes(sku.handle))
          .filter(sku => ((sku.handle !== "monday-morning-suede-boots") || (sku.handle === "monday-morning-suede-boots" && sku.color === "Palissandro")) && ((sku.handle !== "buckle-up-nappa-boots") || (sku.handle === "buckle-up-nappa-boots" && sku.color === "Black")))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw22`}
            />
          ))}
      </ul>
    </div>
  )
}

export default Resort23

export const query = graphql`
    query Resort23Query {
        allProductStyle(filter: {handle: {in: [
            "absolute-40-nappa-sandals",
            "absolute-40-nappa-crystals-sandals",
            "admirer-10-velvet-sandals",
            "grass-roots-45-suede-pumps",
            "grass-roots-45-suede-crystals-pumps",
            "limelight-90-suede-pumps",
            "lure-nappa-sandals",
            "lure-90-nappa-crystals-sandals",
            "luster-10-nappa-sandals",
            "luster-10-nappa-laminata-sandals",
            "rise-up-110-nappa-sandals",
            "rise-up-110-nappa-crystals-sandals",
            "solar-65-nappa-sandals",
            "solar-65-nappa-crystals-sandals",
            "trinity-mid-calf-75-nappa-boots",
            "trinity-mid-calf-75-suede-boots",
            "monday-morning-suede-boots",
            "buckle-up-nappa-boots",
            "off-road-50-nappa-boots",
            "off-road-50-suede-boots",
            "off-duty-suede-boots",
            "cantor-boots",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
