import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Image from "./Image"
import FavoriteIcon from "./FavoriteIcon"
import Price from "./Price"

import { trackEvent } from "../helpers"

import styles from "./productTile.module.scss"
import { SizeSelect } from "./ProductTile"


const SkuTile = ({ index, style, sku, customStyles, origin, setCartItems }) => {

  const handleTileClick = e => {
    trackEvent("Story", "Click", "Grid Item")
  }

  const badgeTag = style.tags && style.tags.find(t => t.includes("BADGE:"))

  // const [selectedSku, setSelectedSku] = useState(sku)
  const [selectedVariant, setSelectedVariant] = useState(sku.variants[0])

  const [showSizeSelect, setShowSizeSelect] = useState(false)

  const handleAtcClick = e => {
    setShowSizeSelect(true)
  }

  // Close size select if clicked out of
  const handleOutsideSizeClick = e => {
    const path = e.composedPath()
    if (showSizeSelect && path) {
      const clickedOnSizeSelect = path.some(el => {
        return el && el.className && (el.className.includes("size") || el.className.includes("bis"))
      })
      if (!clickedOnSizeSelect)
        setShowSizeSelect(false)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined" && window.outerWidth >= 1024)
      document.addEventListener("click", handleOutsideSizeClick)
    return () =>
      document.removeEventListener("click", handleOutsideSizeClick)
  })

  return (
    <li
      role="presentation"
      onClick={handleTileClick}
      className={`${styles.gridItem} ${customStyles.gridItem}`}
    >
      <div className={styles.imageContainer}>
        <Link
          to={`${style.url}?color=${sku.color}&origin=${origin}-${index+1}`}
          className={styles.imageLink}
        >
          <Image
            image={{
              desktopImage: sku.images.plp2.find(image => image.search(/side/i) > -1) || sku.images.plp2[0],
              mobileImage: sku.images.plp2.find(image => image.search(/side/i) > -1) || sku.images.plp2[0],
            }}
            altText={style.title}
            options={{
              sizing: "plp",
            }}
            className={styles.image}
          />
          {(sku.images.plp2 && sku.images.plp2[1]) &&
          <Image
            image={{
              desktopImage: sku.images.plp2.find(image => image.search(/slant/i) > -1)
                    || sku.images.plp2[1],
              mobileImage: sku.images.plp2.find(image => image.search(/slant/i) > -1)
                  || sku.images.plp2[1],
            }}
            altText={style.title}
            options={{
              sizing: "plp",
            }}
            className={styles.imageHover}
          />
          }
        </Link>
        {showSizeSelect &&
          <SizeSelect
            showSizeSelect={showSizeSelect}
            setShowSizeSelect={setShowSizeSelect}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            selectedSku={sku}
            selectedStyle={style}
            collection={origin}
            setCartItems={setCartItems}
          />
        }
      </div>
      <div className={`${styles.info} ${customStyles.info}`}>
        {badgeTag &&
        <h5 className={`${styles.badge} ${customStyles.badge}`}>{badgeTag.replace("BADGE:","")}</h5>
        }
        <h4 className={`${styles.title} ${customStyles.title}`}>
          <Link to={`${style.url}?color=${sku.color}&origin=${origin}-${index+1}`}>{style.title.split(" - ")[0]}</Link>
        </h4>
        <p className={`${styles.price} ${customStyles.price}`}>
          <Price
            price={sku.variants[0].price}
            compareAtPrice={sku.variants[0].compareAtPrice}
            // intlPrices={sku.variants[0].intlPrices}
          />
        </p>
        <p className={`${styles.availabilityNote} ${customStyles.availabilityNote}`}>
          Available in {style.skus.length} color{(style.skus.length > 1) ? "s" : ""}
        </p>
        <FavoriteIcon
          sku={sku.sku}
          style={style}
          className={`${styles.favoriteBtn} ${customStyles.favoriteBtn}`}
        />
        <button
          className={`${styles.atcBtn} ${customStyles.atcBtn}`}
          onClick={handleAtcClick}
        >
          Add to Cart
        </button>
      </div>
    </li>
  )
}

export default SkuTile
